<template>
  <div class="main-container">
    <div class="pt-wrapper">
      <div class="hd-search">
        <table-search
          :model="searchParams"
          label-width="80px"
          :isClear="true"
          :flex="'4'"
          @search="searchFn"
          @clearSearch="clearSearchFn"
        >
          <search-item default label="">
            <el-cascader
              style="width: 100%"
              v-model="sitesOrgArr"
              :options="orgTreeData"
              placeholder="请选择机构"
              clearable
              filterable
              :props="{
                expandTrigger: 'hover',
                label: 'agencyName',
                value: 'agencyId',
                checkStrictly: true,
              }"
            ></el-cascader>
          </search-item>
          <search-item default label="">
            <el-input
              size="small"
              v-model="searchParams.agencyName"
              placeholder="网站名称"
              clearable
            />
          </search-item>
        </table-search>
      </div>
      <div class="bd-content">
        <pageTable
          ref="table"
          row-key="orderId"
          :columns="columnList"
          :params="searchParams"
          :api-function="this.$api.operationOrder.getSitemanagementList"
        >
          <template slot="options" slot-scope="scope">
            <div class="data-operate">
              <span
                class="op-blue"
                @click="webSite(scope.row)"
                style="margin-right: 10px"
                >网站设置</span
              >
              <span
                class="op-blue"
                @click="setColumn(scope.row)"
                style="margin-right: 10px"
                >栏目管理</span
              >
            </div>
          </template>
        </pageTable>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'webManage',
  components: {},
  data() {
    return {
      tableRef: '',
      sitesOrgArr: [],
      orgTreeData: [], //机构
      columns: [],
      columnList: [
        {
          label: '机构名称',
          prop: 'agencyName',
        },
        {
          label: '栏目数量',
          prop: 'channelCount',
        },
        {
          label: '操作',
          prop: 'options',
          show: 'template',
          width: 180,
        },
      ],
      loading: false,
      searchParams: {
        agencyId: '',
        agencyName: '',
      },
      // 是否展开，默认全部折叠
      isExpandAll: false,
      // 重新渲染表格状态
      refreshTable: true,
    };
  },
  mounted() {
    this.tableRef = this.$refs.table;
    this.loadData();
  },
  methods: {
    //加载机构树
    async loadOrgTree() {
      let res = await this.$api.operationOrder.getTreeList();
      if (res.data) {
        this.orgTreeData = res.data;
      }
    },

    async loadData() {
      //加载机构树
      await this.loadOrgTree();
    },
    searchFn(status) {
      if (this.sitesOrgArr.length > 0) {
        this.searchParams.agencyId =
          this.sitesOrgArr[this.sitesOrgArr.length - 1];
      } else {
        this.searchParams.agencyId = '';
      }
      this.tableRef.getPageDataOfAction(status);
    },
    clearSearchFn() {
      this.sitesOrgArr = [];
    },
    setColumn(row) {
      var query = {
        agencyId: row.agencyId,
        agencyName: row.agencyName,
      };
      this.$router.push({
        path: '/webColumn',
        query,
      });
    },
    webSite(row) {
      var query = {
        agencyId: row.agencyId,
        agencyName: row.agencyName,
      };
      this.$router.push({
        path: '/webSetting',
        query,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .bd-content .com-data-table .el-table .el-table__header th.is-leaf {
  background: rgb(241, 241, 241) !important;
}
</style>
